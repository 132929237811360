<template>
    <div class="toolbox-container">
        <div class="tool-container" :class="{ disabled }">
            <slot name="tools" />
        </div>
        <div
            v-show="isToolsCloser"
            class="toolbox-button"
            :class="{ disabled }"
            @click="toggle()"
        >
            <div class="more-icon" />
            <div v-show="isShowToolbox" class="tool-dropdown">
                <slot name="tools" :toggle="toggle" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableTools',
    emits: ['update:tableWidth'],
    props: {
        toolName: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tableWidth: {
            type: Number,
            default: 0
        }
    },
    methods: {
        toggle: function () {
            if (this.disabled) return
            this.isShowToolbox = !this.isShowToolbox
        },
        clickBlankHandler: function (e) {
            if (!this.$el.contains(e.target)) {
                this.isShowToolbox = false
            }
        },
        setDisplay: function () {
            const toolsContainer = this.$el
            const toolElements = toolsContainer.querySelectorAll(
                '.tool-container>div'
            )
            const dropdownElements = toolsContainer.querySelectorAll(
                '.toolbox-button>.tool-dropdown>div'
            )
            const controllerWidth = this.$isMobile() ? 150 : 350
            const toolWidth = this.$isMobile() ? 50 : 135

            const toolList = {}
            const toolDropDownList = {}
            toolElements.forEach((element, index) => {
                toolList[index] = element
            })
            dropdownElements.forEach((element, index) => {
                toolDropDownList[index] = element
            })

            this.isToolsCloser =
                this.tableWidth - controllerWidth <
                toolElements.length * toolWidth

            if (this.isToolsCloser) {
                const lastIndex = toolElements.length - 1

                toolList[lastIndex].style.display = 'none'
                toolDropDownList[lastIndex].style.display = 'flex'

                for (let i = 0; i < toolElements.length; i++) {
                    if (
                        this.tableWidth - controllerWidth <=
                        toolWidth * (1 + i)
                    ) {
                        toolList[i].style.display = 'none'
                        toolDropDownList[i].style.display = 'flex'
                    } else {
                        toolList[i].style.display = ''
                        toolDropDownList[i].style.display = 'none'
                    }
                }
            } else {
                const lastIndex = toolElements.length - 1
                toolList[lastIndex].style.display = ''
                toolDropDownList[lastIndex].style.display = ''
            }
        }
    },
    computed: {},
    watch: {
        tableWidth: function () {
            if (this.tableWidth > 0) this.setDisplay()
        }
    },
    data() {
        return {
            isShowToolbox: false,
            isToolsCloser: false
        }
    },
    mounted() {
        document.addEventListener('click', this.clickBlankHandler)
    },
    unmounted() {
        document.removeEventListener('click', this.clickBlankHandler)
    }
}
</script>

<style lang="scss" scoped>
.toolbox-container {
    display: flex;
    // width: 160px;
    color: $fourth-black;
    font-size: 15px;
    z-index: 5;

    .toolbox-button {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
        color: $fourth-black;
        font: 14px;

        .more-icon {
            width: 24px;
            height: 24px;
        }
        .tool-dropdown {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 160px;
            top: 30px;
            background-color: $primary-white;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
            margin-top: 5px;

            & > :deep(div) {
                &:hover {
                    background: $seventh-grey;
                }
                display: none;
                align-items: center;
                height: 40px;
                width: auto;
                padding: 0 20px;
                cursor: pointer;
                user-select: none;

                .icon {
                    width: 24px;
                    height: 24px;
                    margin-right: 5px;
                }
            }
        }
    }
    .tool-container {
        display: flex;
        cursor: pointer;
        & > :deep(div) {
            display: flex;
            align-items: center;
            height: 30px;
            padding-right: 15px;
            user-select: none;
            div {
                width: max-content;
                &:last-child {
                    display: block;
                }
            }

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }
        }
        &.disabled {
            cursor: not-allowed;
        }
        @media screen and (max-width: 576px) {
            & > :deep(div) {
                padding-right: 10px;
                width: 40px;
                height: 40px;
                div {
                    &:last-child {
                        display: none;
                    }
                }
                .icon {
                    width: 32px;
                    height: 32px;
                    margin-inline: auto;
                }
            }
        }
    }
}
</style>
